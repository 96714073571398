import { Route, Routes } from "react-router-dom";

import AddBreed from "./Breeds/AddBreed";
import Breed from "./Breeds/Breed";
import EditBreed from "./Breeds/EditBreed";
import AddSpices from "./Spices/AddSpices";
import EditSpices from "./Spices/EditSpices";
import Spices from "./Spices/Spices";
import UploadCsvSpices from "./UploadCsvSpices";

function SpicesAndBreedsManagement() {
    return(
        <>
            <Routes>
                <Route index element={<Spices />} />
                <Route path="add" element={<AddSpices />} />
                <Route path="uploadcsvspices" element={<UploadCsvSpices />} />
                <Route path=":id/edit" element={<EditSpices />} />

                <Route path=":id/breeds" element={<Breed />} />
                <Route path=":id/breeds/add" element={<AddBreed />} />
                <Route path=":id/breeds/:breedId/edit" element={<EditBreed />} />
            </Routes>
        </>
    )
}

export default SpicesAndBreedsManagement;