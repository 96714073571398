import { useCallback, useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllPermissions, getRoleWithId, patchRole } from "../../../actions/acl";
import InputField from "../../../components/FormFields/InputField";
import MultiSelectCheckboxField from "../../../components/FormFields/MultiSelectCheckboxField";
// import {
//   editExistingRole,
//   fetchRoleUsingId,
//   getPermissions,
//   PERMISSIONS,
//   ROLES_DETAIL,
// } from "../../../redux/actions/roleManagement";

const EditRole = () => {
  // use hooks
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm({ shouldUnregister: true });

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  // ------------------- Local states ---------------------
  // ------------------- Global states ---------------------
  const detail = {};
  const [permissions, setPermissions] = useState([]);

  // ------------------- Inner functions ---------------------
  const onSubmit = async (data) => {
      try {
       await patchRole({ ...data, roleId: id });
        toast.success("Role edited successfully");
        navigate('/dashboard/roles');
      } catch ({ response }) {
        toast.error(response?.data?.message);
      }
  };

  const fetchRecords = useCallback(async () => {
    try {
      const resp = await getRoleWithId({ roleId: id });
      setValue("role", resp?.data?.responseData?.data?.role?.role, {
        shouldValidate: true,
      });
    
      setPermissions(resp?.data?.responseData?.permissions);
    } catch (error) {
      console.error(error);
    }
  }, [setValue, id]);

  const fetchPermissions = useCallback(async () => {
    try {
      const resp = await getAllPermissions();
      setPermissions(resp?.data?.responseData?.permissions);
    fetchRecords();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchRecords, dispatch, fetchPermissions]);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit role</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {detail === null && <div className="content-loader"></div>}

              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <InputField
                              control={control}
                              label="Role name"
                              name="name"
                              type="text"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "This field is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Permissions</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-12">
                            <MultiSelectCheckboxField
                              control={control}
                              name="permissions"
                              label={"Message type"}
                              placeholder="Message Type"
                              options={permissions}
                              optionValue="id"
                              optionLabel="permissionCode"
                              normalize={(_value) => _value?.value}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Permission is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn orange-btn ${
                          isSubmitting ? "btn-loader" : ""
                        }`}
                      >
                        Update details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditRole;
