import axios from "axios";

export function getAllAnimals(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/pet'
    })
}

export function updatePetStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/pet'
    })
}


export function getAllBreeds(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/bred'
    })
}

export function BreedAdd(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/bred'
    })
}

export function getBreedById(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/bred/id'
    })
}

export function BreedDelete(payload){
    return axios({
        method: 'DELETE',
        data: payload,
        url: '/bred'
    })
}

export function UpdateBreedStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/bred'
    })
}

export function getAllSpecies(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/species'
    })
}

export function getSpeciesById(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/species/id'
    })
}

export function SpeciesAdd(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/species'
    })
}

export function SpeciesDelete(payload){
    return axios({
        method: 'DELETE',
        data: payload,
        url: '/species'
    })
}

export function UpdateSpeciesStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/species'
    })
}

export function uploadCsvSpices(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: '/uploadCsvSpices'
    })
}

export function getAnimalDetail(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: '/pet/id'
    })
}