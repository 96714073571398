import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { confirm } from "react-confirm-box";
import { ALL_REVIEWS } from "../../constants";
import ConfirmAlert from "../../components/Common/Modals/ConfirmAlert";

function LayoutSideNavigation() {
  // local variables
  const [users, setUsers] = useState(false);
  const [cms, setCms] = useState(false);
  const [animalManagement, setAnimalManagement] = useState(false);
  const [aclManagement, setAclManagement] = useState(false);
  const [reportRating, setReportRating] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  // confirm alert options
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No",
    },
  };

  // use hooks
  const navigate = useNavigate();

  // handle users
  const handleUsers = () => {
    setUsers((previous) => !previous);
    setAnimalManagement(false);
    setAclManagement(false);
    setReportRating(false);
    setCms(false);
  };

  //   handle Animal Management
  const handleAnimalManagement = () => {
    setAnimalManagement((previous) => !previous);
    setUsers(false);
    setAclManagement(false);
    setReportRating(false);
    setCms(false);
  };

  //   handle CMS Management
  const handleCMS = () => {
    setCms((previous) => !previous);
    setUsers(false);
    setAnimalManagement(false);
    setAclManagement(false);
    setReportRating(false);
  };

  //   handle Acl Management
  const handleAclManagement = () => {
    setAclManagement((previous) => !previous);
    setUsers(false);
    setAnimalManagement(false);
    setCms(false);
    setReportRating(false);
  };

  //   handle Report And Ratings
  const handleReportAndRatings = () => {
    setReportRating((previous) => !previous);
    setUsers(false);
    setAnimalManagement(false);
    setCms(false);
    setAclManagement(false);
  };

  const onButtonClick = async () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="sidebar-ui-block">
        <div id="" className="menulist-ui list-group">
          {/* Dashbord */}
          <li>
            <NavLink exact className="nav-link list-group-item" to="/dashboard">
              <span className="side-icon">
                <img src="/assets/images/dashboard.svg" alt="" />
              </span>
              <p>Dashboard</p>
            </NavLink>
          </li>

          {/* Users */}
          <li className="dropdown">
            <a
              href="javascript:;"
              onClick={() => {
                handleUsers();
              }}
              className="nav-link list-group-item"
            >
              <span className="side-icon">
                <img src="/assets/images/users.svg" alt="" />
              </span>
              <p>Users</p>
            </a>
            {users ? (
              <ul>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/customers"
                  >
                    <span className="side-icon"></span>
                    <p>Pet Owners</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/vets"
                  >
                    <span className="side-icon"></span>
                    <p>Vets</p>
                  </NavLink>
                </li>
              </ul>
            ) : null}
          </li>

          {/* Animal Management */}
          <li className="dropdown">
            <a
              href="javascript:;"
              onClick={() => {
                handleAnimalManagement();
              }}
              className="nav-link list-group-item"
            >
              <span className="side-icon">
                <img src="/assets/images/animal-management.svg" alt="" />
              </span>
              <p>Animal Management</p>
            </a>
            {animalManagement ? (
              <ul>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/species"
                  >
                    <span className="side-icon"></span>
                    <p>Species/ Breeds</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/animals"
                  >
                    <span className="side-icon"></span>
                    <p>Animals</p>
                  </NavLink>
                </li>
              </ul>
            ) : null}
          </li>

          <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/transactions"
            >
              <span className="side-icon">
                <img src="/assets/images/reports.svg" alt="" />
              </span>
              <p>Transactions</p>
            </NavLink>
          </li>


          {/* Blogs/ Insight */}
          <li className="dropdown">
            <a
              href="javascript:;"
              onClick={() => {
                handleCMS();
              }}
              className="nav-link list-group-item"
            >
              <span className="side-icon">
                <img src="/assets/images/content-management.svg" alt="" />
              </span>
              <p>Blogs</p>
            </a>
            {cms ? (
              <ul>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/blogs/3"
                  >
                    <span className="side-icon"></span>
                    <p>Published</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/blogs/1"
                  >
                    <span className="side-icon"></span>
                    <p>Pending</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/blogs/0"
                  >
                    <span className="side-icon"></span>
                    <p>Draft</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/blogs/2"
                  >
                    <span className="side-icon"></span>
                    <p>Rejected</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/blogs/5"
                  >
                    <span className="side-icon"></span>
                    <p>Revised</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/category"
                  >
                    <span className="side-icon"></span>
                    <p>Category</p>
                  </NavLink>
                </li>

                
              </ul>
            ) : null}
          </li>

          {/* timeslot listing */}
          <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/time-slot"
            >
              <span className="side-icon">
                <img src="/assets/images/time-slot.svg" alt="" />
              </span>
              <p>Time Slot Management</p>
            </NavLink>
          </li>

          <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/caregiver-role"
            >
              <span className="side-icon">
                <img src="/assets/images/acl-img.svg" alt="" />
              </span>
              <p>Caregiver Role</p>
            </NavLink>
          </li>

           {/* plan listing */}
           <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/plans"
            >
              <span className="side-icon">
                <img src="/assets/images/time-slot.svg" alt="" />
              </span>
              <p>Plans</p>
            </NavLink>
          </li>

          {/*  */}
          {/* <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/notifications"
            >
              <span className="side-icon">
                <img src="/assets/images/dashboard.svg" alt="" />
              </span>
              <p>Broadcasts and Push notifications</p>
            </NavLink>
          </li>

          <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/general-settings"
            >
              <span className="side-icon">
                <img src="/assets/images/settings.svg" alt="" />
              </span>
              <p>General Settings</p>
            </NavLink>
          </li> */}

          {/* Ticket Management */}
          {/* <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/tickets"
            >
              <span className="side-icon">
                <img src="/assets/images/dashboard.svg" alt="" />
              </span>
              <p>Ticket Management</p>
            </NavLink>
          </li> */}

          {/*  */}
          {/* <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/payments"
            >
              <span className="side-icon">
                <img src="/assets/images/dashboard.svg" alt="" />
              </span>
              <p>Payament History</p>
            </NavLink>
          </li> */}

          {/* Reports and ratings */}


          <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/ratings"
            >
              <span className="side-icon">
                <img src="/assets/images/reports.svg" alt="" />
              </span>
              <p>Ratings</p>
            </NavLink>
          </li>

          <li className="dropdown">
            <a
              href="javascript:;"
              onClick={() => {
                handleReportAndRatings();
              }}
              className="nav-link list-group-item"
            >
              <span className="side-icon">
                <img src="/assets/images/reports.svg" alt="" />
              </span>
              <p>Reports</p>
            </a>
            {reportRating ? (
              <ul>
                {
                ALL_REVIEWS.length > 0 && ALL_REVIEWS.map((obj, index) => (
                  <li key={obj.code}>
                    <NavLink
                      className="nav-link list-group-item"
                      to={"/dashboard/reports/"+obj.code}
                    >
                      <span className="side-icon"></span>
                      <p>{obj.name}</p>
                    </NavLink>
                  </li>
                ))
              }
              </ul>
            ) : null}
          </li>

          {/* Acl Management */}
          <li className="dropdown">
            <a
              href="javascript:;"
              onClick={() => {
                handleAclManagement();
              }}
              className="nav-link list-group-item"
            >
              <span className="side-icon">
                <img src="/assets/images/acl-img.svg" alt="" />
              </span>
              <p>ACL Management</p>
            </a>
            {aclManagement ? (
              <ul>
                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/roles"
                  >
                    <span className="side-icon"></span>
                    <p>Roles</p>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="nav-link list-group-item"
                    to="/dashboard/staff"
                  >
                    <span className="side-icon"></span>
                    <p>Staff</p>
                  </NavLink>
                </li>
              </ul>
            ) : null}
          </li>

          {/* reports listing */}
          {/* <li>
            <NavLink
              className="nav-link list-group-item"
              to="/dashboard/reports"
            >
              <span className="side-icon">
                <img src="/assets/images/reports.svg" alt="" />
              </span>
              <p>Reports</p>
            </NavLink>
          </li> */}

          {/* logout */}
          <li>
            <a
              href="#"
              className="nav-link list-group-item"
              onClick={() => {
                setConfirmShow(true);
              }}
            >
              <span className="side-icon">
                <img src="/assets/images/logout.svg" alt="" />
              </span>
              <p>Log Out</p>
            </a>
          </li>
        </div>
      </div>
      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title="Are you sure you want to logout?"
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={onButtonClick}
        />
      ) : null}
    </>
  );
}

export default LayoutSideNavigation;
