import { useEffect, useState } from "react";
import moment from 'moment';
import DetailModal from "../../components/Common/Modals/detail";
import { ALL_RATINGS } from "../../constants";
import CommonEmailPage from "../../components/Common/CommonEmailPage";

function TableData({ srno, index, _data, reload }) {
  // local variables
  const [showDetail, setShowDetail] = useState(false);
  const [data, setData] = useState(null);
  const [showEmail, setShowEmail] = useState(false);

  useEffect(() => {
    if (_data) {
      const foundData = ALL_RATINGS?.find(
        (_rating) => _rating?.code == _data?.code
      );
      if (foundData) {
      _data.title = foundData?.name;
      _data.imgUrl = foundData?.imgUrl;

        setData(_data)
      }
    }
  }, [_data]);

  console.log('qqqq', _data);

  return (
    <>
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <div class="table-img-wrpr">
            <img
              className="img-fluid table-img"
              src={data?.imgUrl}
            ></img>
          </div>
        </td>
        <td>
          <a
            className="table-modal only-link"
            href="javascript:;"
            onClick={() => {
              setShowDetail(true);
            }}
          >
            {data?.title} 
          </a>
        </td>
        <td>{_data?.User?.UserProfile?.name ?? null}</td>
        <td>{data?.description}</td>

        <td>{data?.User?.email ?? null}</td>
        <td> {data?.User?.countryCode ?? null} {data?.User?.phoneNumber ?? null}</td>
        <td>{data?.createdAt ? moment(data?.createdAt).format("lll") : null}</td>
        <td>
          <a
            class="sb-clnt"
            href="javascript:void(0)"
            onClick={() => {
              setShowEmail(true);
            }}
          >
            Reply
          </a>
        </td>
      </tr>

      {showEmail ? (
        <CommonEmailPage
          show={showEmail}
          onHide={() => {
            setShowEmail(false);
          }}
          data={data}
          email={data?.User?.email}
          title={data?.title}
        />
      ) : null}
      
      {showDetail ? (
        <DetailModal
          show={showDetail}
          onHide={() => {
            setShowDetail(false);
          }}
          data={data}
          title={"Rating Detail"}
        />
      ) : null}
    </>
  );
}

export default TableData;
