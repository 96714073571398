import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getClinics } from "../../../actions/Users";
import DetailSlide from './DetailSlide';

function Detail() {
  // local variables
  const [detail, setDetail] = useState(null);
  const [clinicList, setClinicList] = useState(null);
  // use hooks
  const location = useLocation();

  const { id } = useParams();


  const fetchData = useCallback( async (userId) => {
   try {
      const resp = await getClinics({userId: id});
      setClinicList(resp?.data?.responseData);   
      
   } catch ({ response, request }) {
     if (response) {
       toast.error(response?.data?.message);
     } else if (request) {
       toast.error("You do not have internet connection");
     }
   }
  },[]);


  useEffect(() => {
    fetchData(id);
    if (location.state) {
      setDetail(location?.state);
      
    }
    
  }, [location]);


  console.log(detail, '========', clinicList);



  return (
    <>
       <div className="row menu-top-block">

        <div className="data-header-left cr-operators">
          <div className="count-data">
            <span className="pr-page"><Link to="/dashboard/vets" className="link-hrf">Vets </Link> </span>
          </div>
          <div className="count-day lk-path">
            <span className="st-current">Clinic</span>
          </div>
        </div>


      </div>
      {
        detail?.UserProfile?.name
        ?
        <div className="container ani-ui-block shop-manager">
          <div className="row menu-top-block">
            <div className="col-sm-12 tl-block align-self-center">
              <h4 className="heading">Detail</h4>
            </div>
          </div>
          <div className="row menu-top-block">
            <div className="col-lg-12 col-md-12">
              <div className="add-left-block">
                <div className="fields-ui-block">
                  <div className="basic-details form-block">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Name:</label>
                          <p>{detail?.UserProfile?.name}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Email Id:</label>
                          <p>{detail?.email}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Gender</label>
                          <p>{detail?.UserProfile?.gender}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Phone Number:</label>

                          <p>
                            {detail?.countryCode &&
                              (detail?.countryCode?.includes("+")
                                ? detail?.countryCode
                                : `+${detail?.countryCode}`)}
                            &nbsp;
                            {detail?.mobile
                              ? detail?.mobile
                              : detail?.phoneNumber
                              ? detail?.phoneNumber
                              : "N.A"}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Country</label>
                          <p>{detail?.UserProfile?.country}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Plan</label>
                          <p>{detail?.UserProfile?.Plan?.defaultContent[0]?.name ? detail?.UserProfile?.Plan?.defaultContent[0]?.name : "---"}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>Start date</label>
                          <p>{detail?.UserProfile?.startDate ? moment(detail?.UserProfile?.startDate).format("lll") : '---'}</p>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-ui">
                          <label>End date</label>
                          <p>{detail?.UserProfile?.endDate ? moment(detail?.UserProfile?.endDate).format("lll") : "---"}</p>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        null
      }

      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Clinic</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="result-listing">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>Sr.no.</th>
                      <th>Profile Image</th>
                      <th className="manage-content">Name</th>
                      <th className="manage-content">Phone Number</th>
                      <th className="manage-content">Address</th>
                      <th className="manage-content">Staff</th>
                      <th className="manage-content">License</th>
                      <th className="manage-content">Signature</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      clinicList?.clinics.length > 0 && clinicList?.clinics.map((obj, index) => (
                         <DetailSlide key={obj.id} slideData={obj} index={index} vetId={detail?.id} /> 
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Detail;
