import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import Inputfield from "../../components/FormFields/InputField";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { removeEmptyFields } from "../../utilities";
import { ALL_RATINGS } from "../../constants";
import { useEffect } from "react";

function RatingFilters({ getFilterFields }) {
  // use hooks
  const { search } = useLocation();

  // use hooks
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({ defaultValues: queryString.parse(search) });

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const values = removeEmptyFields(data);
    const queryParams = new URLSearchParams(values);
    navigate({
      search: queryParams.toString(),
    });
    getFilterFields(values);
  };

  const resetFilter = () => {
    navigate({
      search: "",
    });
    getFilterFields({});
    reset({ title: "", groupId: "", code: "" });
  };

  useEffect(()=> {
  },[]);

  return (
    <>
      <form name="filter" onSubmit={handleSubmit(onSubmit)}>
        <div className="row filter-block">
          <div className="col-sm-12 col-md-12 align-self-center">
            <div className="row align-items-end">
              <div className="col-xl-3 col-lg-4">
                <Inputfield
                  control={control}
                  label="Title"
                  placeholder=""
                  name="title"
                  type="text"
                  fieldClass="form-control"
                />
              </div>

              <div className="col-xl-3 col-lg-4">
                <ReactSelectField
                  control={control}
                  name="code"
                  label="Reaction"
                  placeholder="Select Reaction"
                  options={ALL_RATINGS}
                  optionValue="code"
                  optionLabel="name"
                  normalize={(value) => value?.code}
                />
              </div>

              {/* <div className="col-xl-3 col-lg-4">
                <ReactSelectField
                  control={control}
                  name="status"
                  placeholder="Select Status"
                  options={statusTypes}
                  optionValue="id"
                  optionLabel="label"
                  normalize={(value) => value?.id}
                />
              </div> */}

              <div className="col-xl-3 col-lg-4">
                <div className="row">
                  <div className="col-6 align-self-center">
                    <div className="filter-result-block form-group">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn btn1 btn-center ${
                          isSubmitting ? "btn-loader" : ''
                        }`}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="col-6 align-self-center">
                    <div className="filter-result-block form-group">
                      <button
                        type="button"
                        onClick={resetFilter}
                        className="btn btn1 btn-center"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default RatingFilters;
