import { Link } from "react-router-dom";
import moment from "moment";

function TransactionSlide({ srno, index, data, reload }) {

  return (
      <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>{data?.User?.UserProfile?.name ?? null}</td>
        <td>{data?.Plan?.mainContent[0]?.name ?? null}</td>
        <td>{data?.Plan?.PlanPrices[0]?.Currency?.symbol ?? null}{data?.Plan?.PlanPrices[0]?.amount ?? null}</td>
        <td>{ moment.unix(data?.invoiceObj?.data?.object?.lines?.data[0]?.period?.start).format("lll")}</td>
        <td>{ moment.unix(data?.invoiceObj?.data?.object?.lines?.data[0]?.period?.end).format("lll")}</td>
        <td>{data?.User?.email}</td>
        <td>{data?.User?.countryCode}{data?.User?.phoneNumber}</td>
        <td><a href={data?.invoiceObj?.data?.object?.invoice_pdf} target="_blank" rel="noreferrer">Invoice</a></td>
      </tr>
  );
}

export default TransactionSlide;
