import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

import ToolTip from "../../../components/Common/ToolTip";
import ConfirmAlert from "../../../components/Common/Modals/ConfirmAlert";
import Detail from "../../../components/Common/Modals/detail";
import { firstLetterCapital } from "../../../utilities";
import { updateUser } from "../../../actions/Users";

function TableData({ srno, index, data, reload }) {
  //   local variables
  const [status, setStatus] = useState(data?.status);
  const [confirmShow, setConfirmShow] = useState(false);
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const updateUserStatus = async (data) => {
    try {
      await updateUser({ id: data?.id, status: status === 1 ? 0 : 1});
      setStatus(status === 1 ? 0 : 1);
      toast.success("Status Updated Successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  const deleteRecord = async () => {
    try {
      // await deleteUser({ userId: data?.id });
      toast.success("Vet has been deleted successfully");
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`);
    }
  };

  //   use hooks
  useEffect(() => {
    setStatus(data?.status);
  }, [data.status]);

  return (
    <>
     <tr className="modal-tr">
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <Link
            className="table-modal only-link"
            to={`/dashboard/vets/${data?.id}/detail`}
            state={data}
          >
            {data?.UserProfile?.name}
          </Link>
        </td>
        <td>{data?.email}</td>
        <td>
          +{data?.countryCode}
          &nbsp;
          {data?.phoneNumber}
        </td>
        <td>
          <Link
            className="table-modal only-link"
            to={`/dashboard/vets/${data?.id}/detail`}
            state={data}
          >
            Manage
          </Link>
        </td>

        <td>{data?.Clinics[0]?.isClinic ? 'Clinic' : 'Individual'}</td>
        <td>{data?.UserProfile?.country ?? null}</td>


        <td>{firstLetterCapital(data?.UserProfile?.gender)}</td>
        <td>
          <div
            className="status-ui"
            onClick={() => {
              setDeleteBtnClicked(false);
              setConfirmShow(true);
            }}
          >
            {status === 1 ? (
              <ToolTip title="Click to deactivate">
                <div className="stock-field on-btn">
                  <label className="switch">
                    <div className="switch-slider"></div>
                  </label>
                </div>
              </ToolTip>
            ) : (
              <ToolTip title="Click to activate">
                <div className="stock-field off-btn">
                  <label className="switch">
                    <div className="switch-slider"></div>
                  </label>
                </div>
              </ToolTip>
            )}
          </div>
        </td>

        


        {/* <td>
          <div className="table-btn-block">
            <div className="table-btn-block">
              <Link
                className="btn edit-btn"
                  to={`/dashboard/customers/${data?.id}/edit`}
              >
                Edit
              </Link>

              <button
                className="btn delete-btn"
                onClick={() => {
                  setDeleteBtnClicked(true);
                  setConfirmShow(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </td> */}
      </tr>
      {confirmShow ? (
        <ConfirmAlert
          show={confirmShow}
          onHide={() => {
            setConfirmShow(false);
          }}
          title={
            deleteBtnClicked
              ? "Are you sure you want to delete this Veterinarian?"
              : status === 0
              ? "Are you sure to activate Veterinarians?"
              : "Are you sure to deactivate Veterinarian?"
          }
          confirmButtonName={"Yes"}
          cancelButtonName={"No"}
          onButtonClick={() => {
            deleteBtnClicked ? deleteRecord(data) : updateUserStatus(data);
          }}
        />
      ) : null}

      {showDetail ? (
        <Detail
          show={showDetail}
          onHide={() => {
            setShowDetail(false);
          }}
          data={data}
          title={"Customer Details"}
        />
      ) : null}
    </>
  );
}

export default TableData;
