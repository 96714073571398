import { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import { limit } from "../../constants";
import TableData from "./TableData";
import queryString from "query-string";
import Pagination from "react-js-pagination";

import { getAllReportedIssues } from "../../actions/reports";
import ReportFilters from "./ReportFilters";
import { useLocation, useParams } from "react-router-dom";

function Reports() {
  // use hooks
  const { search } = useLocation();
  const { code } = useParams();
  // local variables
  const [list, setList] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState(queryString.parse(search));

  const fetchData = useCallback(async (page, filters, code) => {
    try {
      const resp = await getAllReportedIssues({ ...filters, pageNumber: page, code });
      setList(resp?.data?.responseData);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  }, []);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const getFilterFields = (filters) => {
    setActivePage(1);
    setFilters(filters);
  };

  useEffect(() => {
    fetchData(activePage, filters, code);
  }, [fetchData, activePage, filters, code]);

  return (
    <>
      <div className="right-ui-block">
        <div className="scrollspy-example">
          <div className="rm-content">
            <div className="row menu-top-block">
              <div className="col-sm-5 tl-block align-self-center">
                <h4 className="heading">
                  Reports ({list?.totalRecords ? list.totalRecords : 0})
                </h4>
              </div>
            </div>
            <ReportFilters getFilterFields={getFilterFields} />

            

            <div className="row">
              <div className="col-sm-12">
                <div className="result-listing">
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Sr.no.</th>
                          <th className="manage-content">Title</th>
                          <th className="manage-content">Species</th>
                          <th className="manage-content">Microchip number</th>
                          <th className="manage-content">User Name</th>
                          <th className="manage-content">User Email</th>
                          <th className="manage-content">User Contact</th>
                          <th className="manage-content">Date</th>
                          <th className="manage-content">Status</th>
                          <th className="manage-content">Send Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list === null ? (
                          <tr>
                            <td colSpan={10} align="center">
                              <div className="table-loader" />
                            </td>
                          </tr>
                        ) : list?.help?.length > 0 ? (
                          list?.help?.map?.((obj, index) => (
                            <TableData
                              key={obj.id}
                              data={obj}
                              index={index}
                              srno={(activePage - 1) * limit}
                              reload={() => fetchData(activePage, "filters")}
                            />
                          ))
                        ) : (
                          <tr>
                            <td
                              className="no-record"
                              colSpan={10}
                              align="center"
                            >
                              No record found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {list?.help?.length > 0 && (
                    <div className="pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={
                          list?.perPage ? list?.perPage : limit
                        }
                        totalItemsCount={
                          list?.totalRecords ? list?.totalRecords : 0
                        }
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
